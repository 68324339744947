import React from 'react'

const CarouselItem = ({data}) => {

    function createToolsList (tools) {
        if (tools.length === 1) return tools[0]
        else if (tools.length === 2) return tools[0] + " and " + tools[1]
        else {
            let str = ""
            for (let i = 0; i < tools.length - 1; i++){
                str += tools[i]
                if (i < tools.length - 2) str += ", "
                else str += ", and " + tools[tools.length - 1]
            }
            return str
        }
    }
  return (
    <div className='carouselTile'>
        <div className='carothing'>
            <div className='carouselPreview'>
            <a  href={data.link} target='_blank'>
                <img 
                src={require("../assets/" + data.thumb + ".gif")} 
                 alt={data.title} title={data.title} className='carouselThumbnail'/>
            </a>
            </div>

            <div className='carouselText'>
                <div className='carouselTitle'>
                    {data.title}
                </div>
                <div className='carouselDetails'>
                    {data.description}
                    <br></br><br></br>
                    Built with {createToolsList(data.tools)}
                </div>
            <div>
                <a  href={data.link} target='_blank'>
                    <button className='customButton'>View Project →</button>
                </a>
            </div>
            </div>
        </div>
    </div>
  )
}

export default CarouselItem