import React from 'react'
import './Tile.css'

const Personal = () => {
  return (
    <div id="personal" className='wrapper'>
        &emsp;
        <div className='tile'>
            <div className='tileTitle'>
              About Me
            </div>
            <div className='tileBody'>
              <div>I'm a Software Developer from Charlottesville, Virginia. I graduated from Full Sail University with a B.S. in Computer Science. 
                I'm passionate about design and user experience, and I'm always keeping up on information about the newest tech.
                You can find me on <a href="https://www.linkedin.com/in/noahvstephenson/" target='_blank' className='contactLink'>LinkedIn</a>, <a href="https://github.com/noahvstephenson" target='_blank' className='contactLink'>GitHub</a>, or email me at <a href="mailto:noahvstephenson@gmail.com" className='contactLink'>noahvstephenson@gmail.com</a>.
            
              </div>
              <br></br>
              <div className='itemTitle'>Frontend</div>
              <div>
                → I work in React.js with Tailwind CSS and JavaScript to design user experiences.
              </div>
              <br></br>
              <div className='itemTitle'>Backend</div>
              <div>
                → I use Node.js along with SQL databases to create server-side APIs.
              </div>
              </div>
        </div>
    </div>
  )
}

export default Personal