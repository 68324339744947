import React from 'react'
import './Tile.css'

const Contact = ({close}) => {
  return (
    <div id="contact" className='wrapper'>
        &emsp;
        <div className='tile'>
            <div className='tileTitle'>
              Contact
            </div>
            <div className='tileBody'>
              You can find me on <a href="https://www.linkedin.com/in/noahvstephenson/" target='_blank' className='contactLink'>LinkedIn</a>, <a href="https://github.com/noahvstephenson" target='_blank' className='contactLink'>GitHub</a>, or email at <a href="mailto:noahvstephenson@gmail.com" className='contactLink'>noahvstephenson@gmail.com</a>.

            </div>
            <button className="customButton" onClick={close}>Close</button>
        </div>
    </div>
  )
}

export default Contact