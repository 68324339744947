import React, { useState } from 'react'
import './NavBar.css';

const NavBar = () => {

  const [darkMode, setDarkMode] = useState(false)

  function changeTheme(){
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle("dark");
  }
  
  return (
    <div className='NavBar' id="top">
      <div className='title'>
        <a href='/#' className='noDecoration'>
                  
          <div className='webName'>Noah Stephenson</div>
        </a>
      </div>
      <div></div>
      <div className='links'>
        <a href="#personal">About Me</a>
        &emsp; 
        <a href="#projects">Projects</a>
        &emsp; 
        <a href="#skills">Skills</a>
      </div>
      <div></div>
      <div className='rightNav'>
        <span className='modeToggle' onClick={changeTheme}>{darkMode ? "Light" : "Dark"} Mode</span>
        {/* <button>Contact me</button> */}
        
        <a  href='https://www.linkedin.com/in/noahvstephenson/' target="_blank" className='noDecoration'>
            <img src={require("../assets/logos/linkedin.png")} alt="LinkedIn" title="LinkedIn" className='socialIcon' />
          </a>

          &emsp;
          <a href='https://github.com/noahvstephenson' target="_blank" className='noDecoration'>
            <img src={require("../assets/logos/github.png")} alt="GitHub" title="GitHub" className='socialIcon' />
          </a>
          &emsp;
          <a href='mailto:noahvstephenson@gmail.com' target="_blank" className='noDecoration'>
            <img src={require("../assets/logos/email.png")} alt="Email" title="Email" className='socialIcon' />
          </a>
      </div>
    </div>
  )
}

export default NavBar