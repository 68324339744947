import React from 'react'
import './Skills.css'
import './Tile.css'
import SkillItem from './SkillItem'

const Skills = () => {
  return (
    <div id="skills" className='wrapper'>
        &emsp;
        <div className='tile'>
            <div className='tileTitle'>
              Skills
            </div>
            <div className='tileBody'>
              <div className='skillsList'>
                <div className='skillsHeader'>Primary Stack</div>
                  <div className='skillsGroup'>
                    <SkillItem data={{title: "React.js", thumb: "React.png"}}/>
                    <SkillItem data={{title: "Tailwind", thumb: "tailwind.png"}}/>
                    <SkillItem data={{title: "Node.js", thumb: "node.png"}}/>
                    {/* <SkillItem data={{title: "SQL", thumb: "sql.png"}}/> */}
                    <SkillItem data={{title: "Git", thumb: "git.png"}}/>
                    <SkillItem data={{title: "VS Code", thumb: "VS.png"}}/>
                  </div>
                <div className='skillsHeader'>Languages</div>
                  <div className='skillsGroup'>
                    <SkillItem data={{title: "JavaScript", thumb: "JavaScript.png"}}/>
                    {/* <SkillItem data={{title: "TypeScript", thumb: "TypeScript.png"}}/> */}
                    <SkillItem data={{title: "HTML", thumb: "HTML.png"}}/>
                    <SkillItem data={{title: "CSS", thumb: "CSS.png"}}/>
                    <SkillItem data={{title: "Python", thumb: "Python.png"}}/>
                    <SkillItem data={{title: "C/C#/C++", thumb: "C.png"}}/>
                  </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Skills