import React, { useState } from 'react'
import './Hero.css';
import Contact from './Contact';


const Hero = () => {

    const [contactModal, setContactModal] = useState(true)

    function toggleContact () {
        setContactModal(!contactModal)
    }
  return (
    <div id="hero" className='wrapper'>
        &emsp;
        <div className='Hero'>
            <div className='phrase'>
                {/* <span className='accent'>Lorem ipsum</span> dolor sit amet. */}
                I'm <span className='accent'>Noah Stephenson.</span>
            </div>

            <div className='heroDescription'>
                {/* Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. */}
                I'm a Software Developer specializing in building web products with excellent user experiences.
            </div>
            
            <div className='cta'>
                {/* <a href='https://www.linkedin.com/in/noahvstephenson/' target="_blank"> */}
                {
                    contactModal ? <button className='customButton skeu' onClick={toggleContact}>Contact me&ensp;→</button>
                    : <div className='contactModal'><Contact close={toggleContact}/></div>
                }
                {/* <button className='customButton skeu' onClick={toggleContact}>Contact me&ensp;→</button> */}
                {/* </a> */}
            </div>

            
        </div>
        {/* <div className={contactModal ? 'hidden' : 'contactModal'}>
            <div className='center '>
                <Contact close={toggleContact}/>
            </div>
        </div> */}
    </div>
  )
}

export default Hero