import React, { useState } from 'react'
import './Tile.css'
import './Projects.css'
import CarouselItem from './CarouselItem';

// Commonjs
const Flickity = require('react-flickity-component');
// Or for ES2015 module
// import Flickity from 'react-flickity-component'

const Projects = () => {

  const flickityOptions = {
      initialIndex: 1,
      // contain: true,
      // prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      // setGallerySize: false
  }
  
  return (
    <div id="projects" className='wrapper'>
        &emsp;
        <div className='tile Projects'>
            <div className='tileTitle'>
              Projects
            </div>
            <div className='tileBody'>
              <CarouselItem data={{
                  title: "Weathever",
                  description: "Weather and forecasting project that connects to geolocation and weather data APIs to give the user their local weather, or for any location.",
                  link: "https://weathever.vercel.app/",
                  thumb: "weathever",
                  tools: ["React.js"]
                }}/>
                <CarouselItem data={{
                  title: "Stockit",
                  description: "Concept for a service that allows startups to connect to realtime stock data by connecting through a custom API to give users access to stock insights.",
                  link: "https://stockit-ns.vercel.app/",
                  thumb: "stockit",
                  tools: ["React.js", "Node.js"]
                }}/>
                <CarouselItem data={{
                  title: "All The Cards",
                  description: "Magic: The Gathering deckbuilder and card database where users can login, browse decks and cards, and upload their own custom decks through an engaging interface.",
                  link: "https://noahvstephenson.github.io/projects/allthecards/",
                  thumb: "allthecards",
                  tools: ["React.js", "Node.js"]
                }}/>
                {/* <CarouselItem data={{
                  title: "Noah Stephenson",
                  description: "This is my portfolio site which I use to showcase my projects, skills, and some information about myself. ",
                  link: "https://noahvstephenson.com/",
                  thumb: "portfolio",
                  tools: ["React.js"]
                }}/> */}
            </div>
        </div>
    </div>
  )
}

export default Projects