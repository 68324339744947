import React from 'react'

const SkillItem = ({data}) => {
  return (
    <div className='skillsItem'>
        <img src={require("../assets/logos/" + data.thumb)} alt={data.title} title={data.title} className='skillsIcon' />
        <br></br>
        {data.title}
    </div>
  )
}

export default SkillItem