import React from 'react'
import './Footer.css';

const Footer = () => {
  

  return (
    <footer className='Footer'>
      <div className='footerContent'>
        {/* <div className='footerTitle'>Site by Noah Stephenson</div> */}
        <div className='social'>
          <a  href='https://www.linkedin.com/in/noahvstephenson/' target="_blank" className='noDecoration'>
            <img src={require("../assets/logos/linkedin.png")} alt="LinkedIn" title="LinkedIn" className='socialIcon invert' />
          </a>

          &emsp;
          <a href='https://github.com/noahvstephenson' target="_blank" className='noDecoration'>
            <img src={require("../assets/logos/github.png")} alt="GitHub" title="GitHub" className='socialIcon invert' />
          </a>
          &emsp;
          <a href='mailto:noahvstephenson@gmail.com' target="_blank" className='noDecoration'>
            <img src={require("../assets/logos/email.png")} alt="Email" title="Email" className='socialIcon invert' />
          </a>
        </div>        
      </div>
    </footer>
  )
}

export default Footer