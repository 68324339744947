import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Hero from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Personal from './components/Personal';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <div className='content'>
        <NavBar/>
        <Hero/>
        <Projects/>
        <Personal/>
        {/* <About/> */}
        <Skills/>
        {/* <Contact/> */}
      </div>
      <Footer/>
    </div>
  );
}

export default App;
